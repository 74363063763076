<template>
  <div style="cursor:pointer">
    <!-- 头部 -->
    <header_public></header_public>
    <div class="maska" v-if="videoState == true" @click="masksCloseFun()"></div>
    <div class="videomasks" v-if="videoState == true">
      <video
        :src="videoSrc"
        controls="controls"
        autoplay
        style="width: 57vw;height:32vw;"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div v-else-if="imgState">
      <div class="maska" @click="masksCloseFun()"></div>
      <div class="imagemasks">
        <img :src="imgSrc" controls="controls" autoplay class="show-img" />
      </div>
    </div>
    <div class="bj_box">
      <div class="bj_box_con height_100">
        <div
          class="bj_boxText flex_style direction_column flex_nowrap align_flex-start justify_around"
        >
          <h3 class="text_color text_family"><i>5G</i>万物互联时代</h3>
          <h1 class="text_color text_family">物联网</h1>
          <p class="text_family">
            开启万物互联的时代，智慧城市真的智慧起来，不仅仅是人人互联的时代。
            5G网络就是为物联网时代服务的，能够完美的实现万物互联。
          </p>
          <span class="text_family"
            >开启万物互联的时代，智慧城市真的智慧起来</span
          >
        </div>
        <div class="bj_boxVideo">
          <!-- 轮播图 -->
          <div class="bannerWrap height_100 width_100">
            <!-- style="width:100%;height:300px;"-->
            <!-- Swiper -->
            <div class="swiper-container video_swiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide video-slide"
                  @click="
                    checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')
                  "
                >
                  <div class="video_img">
                    <img
                      :src="majorvideo[0] ? majorvideo[0]['imageThumb'] : ''"
                      alt
                    />
                  </div>
                  <div class="bf_btn">
                    <img src="../../assets/images/gybf.png" alt />
                  </div>
                </div>
                <div
                  class="swiper-slide video-slide"
                  @click="
                    checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')
                  "
                >
                  <div class="video_img">
                    <img
                      :src="majorvideo[0] ? majorvideo[0]['imageThumb'] : ''"
                      alt
                    />
                  </div>
                  <div class="bf_btn">
                    <img
                      class="height_100 width_100"
                      src="../../assets/images/gybf.png"
                      alt
                    />
                  </div>
                </div>
                <div
                  class="swiper-slide video-slide"
                  @click="
                    checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')
                  "
                >
                  <div class="video_img">
                    <img
                      :src="majorvideo[0] ? majorvideo[0]['imageThumb'] : ''"
                      alt
                    />
                  </div>
                  <div class="bf_btn">
                    <img
                      class="height_100 width_100"
                      src="../../assets/images/gybf.png"
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <!--              <div class="swiper-pagination swiper-pagination-white swiper-video-spit" id="swiper-spit"></div>-->
          <!-- Add Arrows -->
          <!--            <div class="swiper-button-next swiper-button-white video-next"><img src="../../assets/images/jtri_03.png" alt=""></div>-->
          <!--            <div class="swiper-button-prev swiper-button-white video-prev"><img src="../../assets/images/jtle_03.png" alt=""></div>-->
        </div>
      </div>
    </div>
    <!-- 行业前景 -->
    <div class="prospect">
      <div class="con_title">
        <!--        <span class="text_family">技术领域</span>-->
        <h4 class="headline text_family text_color">行业前景</h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="country_con">
        <ol class="ol_style">
          <li class="ol_liStyle text_color text_family">国家相关政策</li>
        </ol>
        <div
          class="countryCon_box flex_style direction_row flex_wrap justify_between"
        >
          <div>
            <h6 class="text_color text_family">
              《工业和信息化部关于推动5G加快发展的通知》
            </h6>
            <p>
              作为2020年针对5G发展的第一个重磅红头文件，工信部在5G网络建设、应用推广、技术发展、安全保障和组织实施多方面进行全面部署，可以看作是2020年度中国5G发展的“总纲领”
            </p>
          </div>
          <div>
            <h6 class="text_color text_family">
              《工业和信息化部关于推动5G加快发展的通知》
            </h6>
            <p>
              为深入贯彻落实党的"十八大"和全国林业厅局长会议、第三届全国林业信息化工作会议精神,推动以物联网为代表的新一代信息技术在林业行业的广泛应用,支撑生态林业和民生
            </p>
          </div>
        </div>
      </div>
      <div class="times_con">
        <ol class="ol_style">
          <li class="ol_liStyle text_color text_family">
            5G时代下的物联网发展前景
          </li>
        </ol>
        <div
          class="timesCon_box flex_style direction_row flex_wrap justify_between"
        >
          <div>
            <ul>
              <li class="times_img">
                <img src="../../assets/images/fzqj1.png" alt />
                <span>01</span>
              </li>
              <li class="times_text">
                <h6 class="text_color text_family">火箭速度的5G</h6>
                <p class="text_family">如果说4G是在跑，5G就是在坐火箭</p>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li class="times_img">
                <img src="../../assets/images/fzqj2.png" alt />
                <span>02</span>
              </li>
              <li class="times_text">
                <h6 class="text_color text_family">还不仅仅是快</h6>
                <p class="text_family">拥有极大的容量和极低的时间延迟</p>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li class="times_img">
                <img src="../../assets/images/fzqj3.png" alt />
                <span>03</span>
              </li>
              <li class="times_text">
                <h6 class="text_color text_family">真正的万物互联</h6>
                <p class="text_family">5G的链接密度超过100万平方公里</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="compete_con">
        <ol class="ol_style">
          <li class="ol_liStyle text_color text_family">
            当下多个巨头的竞争策略
          </li>
        </ol>
        <div class="competeCon_box">
          <ul class="flex_style direction_row flex_wrap">
            <li class="flex_style direction_row flex_nowrap">
              <img src="../../assets/images/cldq.png" alt />
              <div class="compete_text">
                <div class="competeText_title">
                  <h5 class="text_color text_family">腾讯：用户体验追求者</h5>
                  <div class="line"></div>
                </div>
                <p class="text_family">
                  可能是微信入口的加持，可以说腾讯云的用户体验是最佳的，注册使用均非常简单。Tiny
                  os在众多国产IoT操作系统中是最年轻的，不过他们的迭代速度是最快的，经过与Tiny
                  os的创始人沟通他们有专人的团队负责收集GitHub上的Issue并逐一分析排期升级。
                </p>
              </div>
            </li>
            <li class="flex_style direction_row flex_nowrap">
              <img src="../../assets/images/clsj.png" alt />
              <div class="compete_text">
                <div class="competeText_title">
                  <h5 class="text_color text_family">阿里：全栈不留死角</h5>
                  <div class="line"></div>
                </div>
                <p class="text_family">
                  之前阿里的物联网芯片，其操作系统AliThings OS
                  已经发展了两年多，刚刚发布了3.0版本对于物联网结合人工智能方面也做的相当领先。
                  其传统数据库刚刚获得TPC的冠军，可以说是全面无死角的物联网技术栈。
                </p>
              </div>
            </li>
            <li class="flex_style direction_row flex_nowrap">
              <img src="../../assets/images/clcl.png" alt />
              <div class="compete_text">
                <div class="competeText_title">
                  <h5 class="text_color text_family">华为：最佳设备供应商</h5>
                  <div class="line"></div>
                </div>
                <p class="text_family">
                  华为的物联网系列产品，明显带有一股性冷淡之风，其Lite
                  OS迭代并不快，云平台的界面也比较朴实，不过华为产品的稳定度和成熟度一直是行业翘楚。华为主导了物联网NB-lot的技术标准，技术池的深度使华为在长期的竞争中，可以居于不败的地位。
                </p>
              </div>
            </li>
            <li class="flex_style direction_row flex_nowrap">
              <img src="../../assets/images/cldq.png" alt />
              <div class="compete_text">
                <div class="competeText_title">
                  <h5 class="text_color text_family">百度：数据存储者</h5>
                  <div class="line"></div>
                </div>
                <p class="text_family">
                  目前，百度是唯一一个推出时序数据库产品TSDB的大厂。虽然目前还没有操作系统的推出，但是从现在全栈式竞争的趋势看，百度推出自己物联网产品的时间不会太远。
                </p>
              </div>
            </li>
            <li class="flex_style direction_row flex_nowrap">
              <img src="../../assets/images/clsj.png" alt />
              <div class="compete_text">
                <div class="competeText_title">
                  <h5 class="text_color text_family">电信运营商：迷路的玩家</h5>
                  <div class="line"></div>
                </div>
                <p class="text_family">
                  考虑到物联网巨大的规模，尤其是移动物联网的流量必然跑在电信网络上，所以各大运营商都顺势成立了自己的物联网子公司，比如中移物联等。不过目前电信物联公司的业务，几乎都局限在提供NB-loT物联卡服务，而且具体的产品资费也不透明。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 团队成员 -->
    <div class="team">
      <div class="con_title">
        <!--        <span class="text_family">专业素养、行业精英</span>-->
        <h4 class="headline text_family text_color">骨干讲师介绍</h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="teacherBox">
        <!-- 轮播图 -->
        <div class="bannerWrap">
          <!--style="width:100%;height:300px;" -->
          <!-- Swiper -->
          <div class="swiper-container teacher-con">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide teachers_box"
                v-for="(teacher, index) in teacherlist"
                :key="index"
              >
                <a href="javascript:void(0)">
                  <img
                    :src="
                      teacher.imageThumb ? teacher.imageThumb : teacher.image
                    "
                    alt
                  />
                </a>
                <div
                  class="teacher_team_con flex_style direction_column flex_nowrap justify_between"
                >
                  <div
                    class="team_con_top flex_style direction_row flex_nowrap justify_between align_center"
                  >
                    <span class="text_family"></span>
                    <!-- <img src="../../assets/images/quanping.png" alt /> -->
                  </div>
                  <div class="team_con_bottom text_left">
                    <p class="text_family">{{ teacher.title }}</p>
                    <span class="text_family">{{
                      teacher.extend ? teacher.extend.subtitle : ""
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div
              class="swiper-pagination swiper-pagination-white major-spit"
              id="swiper-spit"
            ></div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div
          class="swiper-button-next swiper-button-white teachers-switch-next"
        ></div>
        <div
          class="swiper-button-prev swiper-button-white teachers-switch-prev"
        ></div>
      </div>
      <!-- 教学特点  -->
      <div class="peculiarity">
        <div class="peculiarity_con">
          <div class="con_title">
            <!--            <span class="text_family">我们的工作流程</span>-->
            <h4 class="headline text_family text_color">教学特点</h4>
            <img src="../../assets/images/btzst.png" alt />
          </div>
          <div class="peculiarityBox">
            <ul class="flex_style direction_row flex_nowrap justify_around">
              <li>
                <img src="../../assets/images/jxtd2_03.png" alt />
              </li>
              <li>
                <img src="../../assets/images/1641593143096_.pic_hd.jpg" alt />
              </li>
              <li>
                <img src="../../assets/images/jxtd3_03.png" alt />
              </li>
            </ul>
            <div class="peculiarityImg">
              <img src="../../assets/images/xuxian_03.png" alt />
            </div>
            <ul class="flex_style direction_row flex_nowrap justify_around">
              <li>
                <span class="text_color text_family">授之以渔</span>
                <p>
                  传授不同的学习方法，
                  <br />总有一种适合你 <br />培养良好的学习习惯，受益一生
                </p>
              </li>
              <li>
                <span class="text_color text_family">综合素质</span>
                <p>
                  培养良好的沟通能力和团队
                  <br />协作能力， <br />培养较强的抗压能力和解决
                  <br />问题的能力
                </p>
              </li>
              <li>
                <span class="text_color text_family">就业帮扶</span>
                <p>
                  精准就业指导和全套模拟面试，
                  <br />全套面试资料共享和快速 <br />就业秘诀分享
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 工作流程 -->
      <div class="process_box">
        <div class="con_title" style="margin-top: 0;">
          <!--          <span class="text_family">我们的工作流程</span>-->
          <h4 class="headline text_family text_color">5.0新课程</h4>
          <img src="../../assets/images/btzst.png" alt />
        </div>
        <div class="process flex_style direction_column justify_center">
          <div class="processImg">
            <img src="../../assets/images/wlwzxkc.png" alt />
          </div>
        </div>
      </div>
      <!-- 课程 人才发展机遇 -->
      <div class="course_box">
        <div class="course flex_style direction_column justify_center">
          <img src="../../assets/images/kcbjt_03.png" alt />
        </div>
        <div class="opportunity">
          <div class="con_title">
            <!--            <span class="text_family">我们的工作流程</span>-->
            <h4 class="headline text_family text_color">人才发展机遇</h4>
            <img src="../../assets/images/btzst.png" alt />
          </div>
          <ul class="flex_style direction_row flex_nowrap justify_between">
            <li>
              <div class="opportunity_title">
                <p class="text_color text_family">市场需求</p>
                <div class="line"></div>
              </div>
              <div
                class="opportunity_text flex_style direction_row flex_nowrap justify_start"
              >
                <img src="../../assets/images/jydq.png" alt />
                <p class="text_family">
                  5G推动物联网快速进入智能物联，物联网技术门槛高，人才储备不足，市场需求却爆发式增长，物联网人才成为企业抢夺的热门人才。
                </p>
              </div>
            </li>
            <li>
              <div class="opportunity_title">
                <p class="text_color text_family">人才储备</p>
                <div class="line"></div>
              </div>
              <div
                class="opportunity_text flex_style direction_row flex_nowrap justify_start"
              >
                <img src="../../assets/images/jysj.png" alt />
                <p class="text_family">
                  由于物联网技术的专业性，相较于其他编程技术而言，国内人才储备较少，每年人才缺口达百万，并以20%速度增长。
                </p>
              </div>
            </li>
            <li>
              <div class="opportunity_title">
                <p class="text_color text_family">就业薪资</p>
                <div class="line"></div>
              </div>
              <div
                class="opportunity_text flex_style direction_row flex_nowrap justify_start"
              >
                <img src="../../assets/images/jytb.png" alt />
                <p class="text_family">
                  技术的新走向，也拔高了物联网人才的薪资。从职友集上可以查询（以北京为
                  例），物联网工程师平均月薪可达22700
                  元，再加上年终奖，年薪50w-90w不等。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 方向主任 -->
      <!--        <div class="director flex_style direction_column justify_center">-->
      <!--            <div class="director_con flex_style direction_row flex_nowrap justify_around">-->
      <!--                <div class="director_img">-->
      <!--                    <img src="../../assets/images/fxzr_03.png" alt="">-->
      <!--                </div>-->
      <!--                <div class="director_text flex_style direction_column flex_nowrap align_flex-start">-->
      <!--                    <div class="con_title director_title">-->
      <!--                        <span class="text_family">关于公司</span>-->
      <!--                        <h4 class="headline text_family text_color">方向主任履历介绍</h4>-->
      <!--                        <img src="../../assets/images/btzst.png" alt="">-->
      <!--                    </div>-->
      <!--                    <ul class="flex_style direction_column flex_nowrap align_flex-start">-->
      <!--                        <li><p>将近12年的物联网从业经历，5年编程开发经验，7年IT培训经验，4年 教学管理经验</p></li>-->
      <!--                        <li>-->
      <!--                            <p>2016年至今 北京积云教育物联网专业主任</p>-->
      <!--                            <p>培养多名部门精英人才，都在一线任职</p>-->
      <!--                            <p>培养1000多名毕业生，都在北京互联网公司稳定工作</p>-->
      <!--                        </li>-->
      <!--                        <li><p>2013-2016年，易道用车公司负责易道用车移动端开发</p></li>-->
      <!--                        <li><p>2009-2013年，北京艾迪互动公司担任移动端开发工程师</p></li>-->
      <!--                        <li><p>踏踏实实做人，扎扎实实做事，追求工匠精神，知行合一。</p></li>-->
      <!--                    </ul>-->
      <!--                </div>-->
      <!--            </div>-->
      <!--        </div>-->
      <!-- 学员作品 -->
      <div class="works">
        <div class="con_title">
          <!--          <span class="text_family">我们的工作流程</span>-->
          <h4 class="headline text_family text_color">学员作品</h4>
          <img src="../../assets/images/btzst.png" alt />
        </div>
        <ul class="flex_style direction_row flex_wrap justify_between">
          <li v-for="(student, index) in studentWorks" :key="index">
            <img
              :src="student.imageThumb ? student.imageThumb : student.image"
              alt
              @click="checkimgFun(student.image ? student.image : '')"
            />
          </li>
        </ul>
      </div>

      <!-- 就业方向 -->
      <div class="employment">
        <div class="employment_box">
          <div class="con_title">
            <!--            <span class="text_family employment_title">技术领域</span>-->
            <h4 class="headline text_family text_color employment_title">
              就业方向
            </h4>
            <img src="../../assets/images/btzst.png" alt />
          </div>
          <div class="data_img">
            <img src="../../assets/images/jyfx.png" alt />
          </div>
          <ul
            class="employment_list flex_style direction_row flex_wrap justify_between"
          >
            <li class="text_left">
              <img src="../../assets/images/jyfx4.png" alt />
              <div>
                <h6>物联网开发工程师</h6>
                <p>
                  市场需求大，平均就业
                  <br />待遇好达15k
                </p>
              </div>
            </li>
            <li class="text_left">
              <img src="../../assets/images/jyfx2.png" alt />
              <div>
                <h6>移动端开发工程师</h6>
                <p>
                  移动设备的时代
                  <br />移动智能设备开发已成必然
                </p>
              </div>
            </li>
            <li class="text_left">
              <img src="../../assets/images/jyfx3.png" alt />
              <div>
                <h6>物联网架构师</h6>
                <p>
                  传说中年薪百万都很难
                  <br />招到人才的职位
                </p>
              </div>
            </li>
            <li class="text_left">
              <img src="../../assets/images/jyfx1.png" alt />
              <div>
                <h6>微信小程序</h6>
                <p>覆盖行业最广的职位之一</p>
              </div>
            </li>
          </ul>
          <!--                <div class="averageSalary">-->
          <!--                   <ul class="flex_style alary_list direction_row flex_nowrap">-->
          <!--                       <li class="first_li flex_style"> -->
          <!--                           <span>成为学员并 <br /> 了解我们最出众的学员和故事。</span>-->
          <!--                           <p>毕业<i>薪资，</i><i>一</i> <br />起看<i>学员</i>成长。</p>-->
          <!--                       </li>-->
          <!--                       <li class="flex_style border_left">-->
          <!--                           <span>2016，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                       </li>-->
          <!--                       <li class="flex_style border_left">-->
          <!--                           <span>2014，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                       </li>-->
          <!--                   </ul>-->
          <!--                   <ul class="flex_style alary_list">-->
          <!--                       <li class="flex_style">-->
          <!--                           <span>2016，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                       </li>-->
          <!--                       <li class="flex_style border_left">-->
          <!--                           <span>2014，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                       </li>-->
          <!--                       <li class="flex_style border_left">-->
          <!--                           <span>2014，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                       </li>-->
          <!--                       <li class="flex_style border_left last_li">-->
          <!--                           <span>2014，平均薪资1680元</span>-->
          <!--                           <p>873人高薪就业 携手碾 压最难就业季</p>-->
          <!--                           <img src="../../assets/images/bf.png" alt="">-->
          <!--                       </li>-->
          <!--                   </ul>-->
          <!--                </div>-->
        </div>
      </div>

      <!-- 毕业感言-->
      <recollections_public major="物联网"></recollections_public>
    </div>
    <bottom_public></bottom_public>
  </div>
</template>
<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import bottom_public from "../../components/bottom_public/bottom_public.vue";
import header_public from "../../components/header_public/header_public.vue";
import recollections_public from "../../components/recollections_publc/recollections_publc.vue";

const axios = require("axios").default;
export default {
  metaInfo: {
    title: "5G物联网培训|物联网培训|5G物联网培训机构-积云教育",
    meta: [
      {
        name: "description",
        content:
          "积云教育5G物联网培训，紧随5G时代快速发展，精心设置最新5G物联网课程体系，为企业培养掌握智能产品开发技术的高薪人才。",
      },
      {
        name: "keywords",
        content:
          "5G物联网培训,5G物联网,物联网培训,android培训,5G物联网培训机构",
      },
    ],
  },
  //在这里调用ajax请求方法
  created() {
    this.getTeachers(); //教师团队
    this.getStudentWorks();
    this.getstumessage(); //获取学员感言信息
    this.getmajorvideo(); //获取专业宣传视频
  },
  data() {
    return {
      teacherlist: {},
      studentWorks: {},
      stumessage: "",
      videoSrc: "",
      videoState: false,
      imgSrc: "",
      imgState: false,
      majorvideo: "",
    };
  },
  components: {
    bottom_public,
    header_public,
    recollections_public,
  },
  methods: {
    getTeachers() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("教师团队") +
            "&major=" +
            encodeURIComponent("物联网")
        )
        .then((res) => {
          this.teacherlist = res.data.data; //教师团队列表数据
          this.$nextTick(() => {
            new Swiper(".teacher-con", {
              slidesPerView: 4,
              slidesPerGroup: 4,
              autoplay: {
                delay: 3000,
                disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
                stopOnLastSlide: false, // 在最后一页停止
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".teachers-switch-next",
                prevEl: ".teachers-switch-prev",
              },
              loop: true,
            });
          });
        });
    },
    getStudentWorks() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("学员作品") +
            "&major=" +
            encodeURIComponent("物联网") +
            "&limit=6"
        )
        .then((res) => {
          this.studentWorks = res.data.data;
          this.$nextTick(() => {
            new Swiper(".video_swiper", {
              spaceBetween: 30,
              effect: "fade",
              // autoplay: {
              //     delay: 3000,
              //     disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
              //     stopOnLastSlide: false // 在最后一页停止
              // },
              pagination: {
                el: ".swiper-video-spit",
                clickable: true,
              },
              navigation: {
                nextEl: ".video-next",
                prevEl: ".video-prev",
              },
            });
          });
        });
    },
    getstumessage() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("学员感言") +
            "&major="
        )
        .then((res) => {
          this.stumessage = res.data.data; //学员感言列表数据
        });
    },
    getmajorvideo() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("专业宣传") +
            "&major=" +
            encodeURIComponent("物联网")
        )
        .then((res) => {
          this.majorvideo = res.data.data; //学员感言列表数据
        });
    },
    checkVideoFun(src) {
      this.videoState = true;
      this.videoSrc = src;
    },
    checkimgFun(src) {
      this.imgState = true;
      this.imgSrc = src;
    },
    masksCloseFun() {
      this.videoState = false;
      this.imgState = false;
    },
  },
  mounted() {
    // 【注意2:】
    // 轮播图 · 初始化设定
  },
};
</script>
<style lang="scss" scoped>
#header {
  // position: static;
  background: linear-gradient(
    -90deg,
    rgba(134, 6, 238, 1),
    rgba(220, 35, 252, 1)
  ) !important;
}

.bj_box {
  margin-top: 5.4vw;
}

.flex_style {
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.direction_column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex_wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex_nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.align_flex-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.align_center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.align_end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
}

.justify_start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
}

.justify_end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}

.justify_center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.justify_around {
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
}

.justify_between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.width_100 {
  width: 100%;
}

.height_100 {
  height: 100%;
}

.text_color {
  color: rgba(29, 21, 92, 1) !important;
}

.text_family {
  font-family: PingFang;
}

.headline {
  line-height: 3.5vw;
  font-size: 2.25rem;
  font-weight: 800;
}

.subtitle {
  font-size: 1.875rem;
  font-weight: 800;
}

.prospect,
.bj_box_con {
  width: 62.5vw;
  margin: 0 auto;
}

.bj_box_con,
.bannerWrap {
  position: relative;
}

.bj_box {
  width: 100%;
  height: 73.958vw;
  background: url("~@/assets/images/bannerbj.png") no-repeat right;
  background-size: auto 100%;
  -moz-background-size: cover;
  background-attachment: scroll;
}

.bj_boxText {
  position: absolute;
  top: 12.5vw;
  left: 0;
}

.bj_boxText h3 {
  font-size: 2rem;
  font-weight: 800;
}

.bj_boxText h3 i {
  font-size: 8.125rem;
  font-weight: bold;
}

.bj_boxText h1 {
  font-size: 8.125rem;
  font-weight: 800;
}

.bj_boxText p {
  width: 27vw;
  line-height: 1.5vw;
  text-align: left;
  margin-bottom: 1.6vw;
}

.bj_boxText p,
.bj_boxText span {
  font-size: 1rem;
  font-weight: bold;
  color: rgba(107, 107, 107, 1);
}

.bj_boxVideo {
  width: 100%;
  height: 23.4375vw;
  position: absolute;
  bottom: 1vw;
}

.video_swiper {
  height: 100% !important;
}

.video_img,
.video_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bf_btn {
  width: 8.333vw;
  height: 8.333vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bf_btn img {
  width: 100%;
  height: 100%;
}

.video-slide {
  width: 100% !important;
  height: 100% !important;
  background-color: #363636;
}

.video-next,
.video-prev {
  width: 2.916vw;
  height: 2.916vw;
  border-radius: 50%;
  opacity: 1 !important;
  outline: none;
}

.video-next {
  background-color: #ffffff;
  right: -5vw !important;
}

.video-prev {
  background-color: #f5f5f5;
  left: -5vw !important;
}

.video-next::after,
.video-prev::after {
  content: none;
}

.video-next img,
.video-prev img {
  width: 100%;
  height: 100%;
}

.swiper-video-spit {
  left: 0;
  right: 0;
  bottom: -2.5vw;
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #1d155c; /* 两种都可以 */
}

// #swiper-spit span{
//   display: inline-block;
//   margin: 0 4px;
// }

.con_title {
  margin: 7.8125vw 0 3.333vw 0;
}

.con_title img {
  width: 3.541vw;
  height: 0.729vw;
  margin-top: 0.54vw;
}

.con_title span {
  font-size: 14px;
  font-weight: bold;
  color: rgba(102, 102, 102, 1);
}

.ol_style {
  width: 16%;
  margin: 0 auto 4.427vw;
  list-style: disc;
}

.ol_liStyle {
  font-size: 1.875rem;
  font-weight: 800;
}

.country_con,
.timesCon_box {
  width: 100%;
  margin: 0 auto 4.166vw;
}

.countryCon_box div {
  width: 48%;
  height: 14.6875vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 36px 4px rgba(16, 10, 67, 0.15);
  border-radius: 5px;
  overflow: hidden;
}

.countryCon_box div:hover {
  transform: translateY(-20px);
  transition: all 1s;
}

h6 {
  font-size: 1.375rem;
  font-weight: 800;
}

.countryCon_box div p {
  font-size: 1rem;
  font-weight: bold;
  padding: 0 3.33vw;
  text-align: justify;
  color: rgba(102, 102, 102, 1);
  line-height: 1.5vw;
}

.countryCon_box div h6 {
  margin: 2.708vw 0 2.604vw 0;
}

.times_con ol,
.compete_con ol {
  width: 32%;
}

.timesCon_box div {
  width: 30%;
  height: 14.687vw;
}

.timesCon_box h6 {
  margin: 0;
}

.times_img img {
  width: 10.677vw;
  height: 8.854vw;
}

.times_img {
  position: relative;
}

.times_img span {
  display: block;
  width: 2.187vw;
  height: 2.187vw;
  line-height: 2.187vw;
  position: absolute;
  top: -0.3vw;
  right: 4.7vw;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 50%;
}

.times_text p {
  line-height: 2.5vw;
  font-size: 1.125rem;
  font-weight: bold;
  color: rgba(109, 109, 109, 1);
}

.competeCon_box ul li {
  width: 33%;
  margin-bottom: 3.125vw;
}

.competeCon_box ul li:nth-child(4),
.competeCon_box ul li:nth-child(5) {
  margin-bottom: 0 !important;
}

.compete_text {
  width: 14.166vw;
  margin-left: -1.602vw;
  padding-top: 2.2vw;
}

.competeText_title {
  margin-bottom: 1.7708vw;
}

.competeText_title h5 {
  font-size: 1.375rem;
  font-weight: 800;
  text-align: left;
  margin-bottom: 10px;
}

.line {
  width: 2.864vw;
  height: 0.104vw;
  background: rgba(229, 229, 229, 1);
}

.compete_text p {
  text-align: left;
  text-align: justify;
  line-height: 1.477vw;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(103, 103, 103, 1);
}

.competeCon_box img {
  width: 8.125vw;
  height: 8.125vw;
}

.teacherBox {
  width: 62.5vw;
  margin: 0 auto;
  position: relative;
}

.teacher-con {
  height: 19.8vw !important;
}

.teachers_box {
  width: 15.625vw !important;
  height: 15.625vw !important;
  position: relative;
  border-radius: 5px;
  margin-right: 0 !important;
}

.teachers_box a {
  display: inline-block;
  width: 92%;
  height: 100%;
  overflow: hidden;
}

.teachers_box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: all 1.5s; /*图片放大过程的时间*/
  position: relative;
}

.teachers_box a img:hover {
  cursor: pointer;
  transform: scale(1.2); /*以y轴为中心旋转*/
}

.teacher_team_con {
  width: 92%;
  height: 16%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 14px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team_con_top span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 3px 5px 4px #000000;
}

.team_con_top img {
  width: 1.666vw !important;
  height: 1.666vw !important;
}

.team_con_bottom p {
  font-size: 1.35rem;
  font-weight: bold;
  color: #ffffff;
}

.team_con_bottom span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.5vw;
}

.major-spit {
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #e11e2c; /* 两种都可以 */
}

.teachers-switch-next,
.teachers-switch-prev {
  border-radius: 50%;
  width: 2.604vw;
  height: 2.604vw;
  background-color: #000000;
  opacity: 1 !important;
}

.teachers-switch-next,
.teachers-switch-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 45% !important;
}

.teachers-switch-next {
  right: -4.25rem !important;
}

.teachers-switch-prev {
  left: -4.25rem !important;
}

.teachers-switch-next:after,
.teachers-switch-prev:after {
  font-size: 1.125rem;
}

.peculiarity {
  width: 100%;
  height: 57.291vw;
  background: url("~@/assets/images/jxtd.png") no-repeat left center;
  background-size: 40% 60%;
  -moz-background-size: 40% 60%;
  padding-top: 8.7vw;
  box-sizing: border-box;
}

.peculiarity_con {
  width: 62.5vw;
  margin: 0 auto;
  padding-bottom: 3.645vw;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
}

.peculiarityBox ul:first-child li {
  height: 14.0625vw;
}

.peculiarityBox ul li {
  width: 31%;
  border-radius: 5px;
}

.peculiarityBox ul li img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.peculiarityBox ul li span {
  display: inline-block;
  font-size: 1.375rem;
  font-weight: 800;
  margin-bottom: 1.3vw;
}

.peculiarityBox ul li p {
  font-size: 1.125rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(109, 109, 109, 1);
  line-height: 1.354vw;
}

.peculiarityImg {
  margin-bottom: 2vw;
}

.peculiarityImg img {
  width: 100%;
  height: 100%;
  margin-top: 1vw;
}

.process_box {
  margin-top: 7.208vw;
}

.process {
  height: 41.614vw;
}

.processImg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.processImg img {
  width: 100%;
  height: 100%;
}

.course_box {
  width: 100%;
  height: 72.916vw;
  background: url("~@/assets/images/jxtd.png") no-repeat top left;
  background-size: 80% 90%;
  -moz-background-size: 80% 90%;
  overflow: hidden;
}

.course {
  width: 62.5vw;
  height: 33.333vw;
  margin: 5.9375vw auto 0;
}

.course img {
  width: 100%;
  height: 100%;
}

.opportunity {
  width: 62.5vw;
  margin: 0 auto;
}

.opportunity ul li {
  width: 33%;
}

.opportunity_title p {
  font-size: 1.1875rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
}

.opportunity_text {
  margin-top: 1.5625vw;
}

.opportunity_text img {
  width: 2.8125vw;
  height: 2.8125vw;
  margin-right: 15px;
}

.opportunity_text p {
  width: 70%;
  line-height: 1.66vw;
  text-align: justify;
  font-size: 0.9375rem;
  font-weight: bold;
  color: rgba(109, 109, 109, 1);
}

.director {
  width: 100%;
  height: 39.635vw;
  margin-bottom: 62px;
  background: linear-gradient(
    90deg,
    rgba(60, 45, 198, 1) 0%,
    rgba(101, 38, 142, 1) 100%
  );
}

.director_con {
  width: 62.5vw;
  height: 27.5vw;
  margin: 0 auto;
}

.director_img {
  width: 21.093vw;
  height: 27.5vw;
}

.director_img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.director_text {
  width: 25.833vw;
}

.director_title {
  text-align: left;
}

.director_title span {
  font-weight: bold;
  color: rgba(240, 240, 240, 1) !important;
}

.director_title h4 {
  color: rgba(254, 254, 255, 1) !important;
}

.director_text ul li {
  margin-bottom: 25px;
}

.director_text p {
  font-size: 0.9375rem;
  text-align: justify;
  font-family: PingFang;
  line-height: 24px;
  font-weight: bold;
  color: rgba(240, 240, 240, 1);
}

.works {
  width: 62.5vw;
  margin: 0 auto;
}

.works ul li {
  width: 30%;
  height: 16.041vw;
  margin-bottom: 2.8125vw;
}

.works ul li > img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 12px 26px 1px rgba(206, 212, 239, 0.63);
  border-radius: 36px 0px 36px 0px;
}

.employment {
  width: 100%;
  height: 80vw;
  margin-top: 5.208vw;
  box-sizing: border-box;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(60, 45, 198, 1) 0%,
    rgba(101, 38, 142, 1) 100%
  );
}

.employment_box {
  width: 62.5vw;
  margin: 0 auto;
}

.employment_title {
  color: rgba(254, 254, 254, 1) !important;
}

.data_img {
  width: 86%;
  height: 26.7vw;
  margin: 0 auto;
}

.data_img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.employment_list li {
  width: 49%;
  height: 11.51vw;
  margin-bottom: 1.5625vw;
  position: relative;
  color: #ffffff;
}

.employment_list li:hover {
  transform: translateY(-10px);
  transition: all 1s;
}

.employment_list li:nth-child(3),
.employment_list li:nth-child(4) {
  margin-bottom: 0 !important;
}

.employment_list li img {
  // width: auto;
  // height: auto;
  // max-width: 100%;
  // max-height: 100%;
  width: 100%;
  height: 100%;
}

.employment_list li div {
  position: absolute;
  width: 60%;
  height: 70%;
  top: 2.708vw;
  left: 1.5625vw;
  right: 0;
  bottom: 0;
}

.employment_list li div > h6 {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 600;
  margin-bottom: 1.522vw;
}

.employment_list li div > p {
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 1.77vw;
}

.averageSalary {
  margin-top: 150px;
}

.averageSalary ul,
.averageSalary ul li {
  height: 14.604vw;
}

.averageSalary ul {
  border-left: none !important;
  border-top: none !important;
  border: 1px solid rgba(240, 240, 240, 1);
}

.last_li {
  position: relative;
}

.last_li img {
  width: 1.875vw;
  height: 1.875vw;
  position: absolute;
  top: 50px;
  right: 25px;
}

.alary_list li {
  width: 15.625vw;
  text-align: left;
  padding-left: 25px;
  box-sizing: border-box;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 1);
}

.alary_list li span {
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(215, 7, 47, 1);
  line-height: 7rem;
}

.alary_list li p {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

.alary_list li i {
  color: #d7072f;
}

.first_li {
  color: #ffffff !important;
  background-color: transparent !important;
  padding-left: 40px !important;
  width: 31.25vw !important;
}

.first_li span {
  color: #ffffff !important;
  line-height: 2rem !important;
}

.first_li p {
  color: #ffffff !important;
  font-size: 3rem !important;
}

.border_left {
  border-left: 1px solid rgba(240, 240, 240, 1);
}

.text_left {
  text-align: justify;
}

.arrowBox {
  width: 7.805vw !important;
  position: absolute;
  right: 0;
  top: -3vw;
}

.arrowBox img {
  width: 2.604vw;
  height: 2.604vw;
  margin-left: 10px;
}

.foot_box {
  margin-top: 0 !important;
}

@media screen and (max-width: 1200px) {
  .bj_boxText h3 {
    font-size: 1.8rem;
  }
  .bj_boxText h3 i,
  .bj_boxText h1 {
    font-size: 7.125rem;
  }
  .bj_boxText p {
    width: 30vw;
    line-height: 1.4vw;
    margin-bottom: 1.2vw;
  }
}

@media screen and (max-width: 1125px) {
  .bj_boxText h3 {
    font-size: 1.6rem;
  }
  .bj_boxText h3 i,
  .bj_boxText h1 {
    font-size: 6.125rem;
  }
  .bj_boxText p {
    width: 30vw;
    line-height: 1.2vw;
    margin-bottom: 1vw;
  }
}

@media screen and (max-width: 1024px) {
  .bj_boxText p {
    width: 30vw;
    line-height: 1.6vw;
    margin-bottom: 1vw;
  }
}

.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.imagemasks {
  max-width: 1000px;
  position: fixed;
  left: 50%;
  top: 58%;
  overflow: auto;
  height: 550px;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.imagemasks::-webkit-scrollbar {
  display: none;
}

.imagemasks img {
  width: 100%;
}
</style>
